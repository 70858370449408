<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top: 50px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">主增值模块</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">我们的增值优势，专业全面</p>
      </div>

      <div class="content">
        <div class="c-l">
          <div
            class="list animate__animated animate__fadeInLeft animate__delay-0.1s"
          >
            <p class="text">房屋租赁</p>
            <p class="p">用户可进行发布房源，也可进行找房租赁，实现创收生态</p>
          </div>
          <div
            class="list animate__animated animate__fadeInLeft animate__delay-0.3s"
            style="margin-right: 50px"
          >
            <p class="text">家政保洁</p>
            <p class="p">
              实时保洁需求，实时下单响应，安全，高效，且专业，人员透明
            </p>
          </div>
          <div
            class="list animate__animated animate__fadeInLeft animate__delay-0.6s"
            style="margin-right: 50px"
          >
            <p class="text">管道疏通</p>
            <p class="p">
              管道疏通，管道堵塞，实时下单，可享受专业管道师傅上门安装维修
            </p>
          </div>
          <div
            class="list animate__animated animate__fadeInLeft animate__delay-0.9s"
          >
            <p class="text">保姆月嫂</p>
            <p class="p">装束家政顾问对接，按照客户的需求匹配对应的育儿嫂</p>
          </div>
        </div>

        <div class="img">
          <el-image
            style="width: 350px; height: 350px"
            :src="require('../assets/platformp/box.png')"
          ></el-image>
        </div>

        <div class="c-r">
          <div class="list animate__animated animate__fadeInRight animate__delay-0.1s">
            <p class="text">家电维修</p>
            <p class="p">对家庭电气设备进行修复和保养</p>
          </div>
          <div class="list animate__animated animate__fadeInRight animate__delay-0.3s" style="margin-left: 50px">
            <p class="text">孤老照料</p>
            <p class="p">在老年人的日常生活中提供各种陪同，各种护养</p>
          </div>
          <div class="list animate__animated animate__fadeInRight animate__delay-0.6s" style="margin-left: 50px">
            <p class="text">管道疏通</p>
            <p class="p">
              生活配送、代扔垃圾、代取快递、代办一切证件文件，公章琐事
            </p>
          </div>
          <div class="list animate__animated animate__fadeInRight animate__delay-0.9s">
            <p class="text">保姆月嫂</p>
            <p class="p">货物的转载、运输和卸货，一站式搬家服务</p>
          </div>
        </div>
      </div>
    </div>
    <div class="t-con">
      <div class="text">
        <p>平台创收，服务便利， 三赢模式</p>
        <p>
          业主可在手机上直接发起增值需求，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造业主
        </p>
      </div>
    </div>

    <div class="top bottom">
      <div class="title">
        <div class="lent"></div>
        <div class="title-text">便捷增值</div>
        <div class="lent"></div>
      </div>
      <p class="title-bottom-font">我们的八大优势，专业全面</p>
    </div>

    <div class="page_advantage" id="scrollinto_4">
      <div class="advantage_bottom">
        <div
          class="advantage_list"
          v-for="item in advantage_list"
          :key="item.title"
        >
          <div class="advantage_content">
            <el-row :gutter="50">
              <el-col :span="10">
                <div class="advantage_left">
                  <img class="advantage__img" :src="item.imgStr" />
                </div>
              </el-col>
              <el-col :span="14">
                <div class="advantage_right">
                  <div class="right_top">
                    <div class="right_topTxt">{{ item.title }}</div>
                  </div>
                  <div class="right_bottom">{{ item.content }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zoomValue: 0,
      advantage_list: [
        {
          title: "车辆维修",
          content:
            "业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业在线客服中心",
          imgStr: require("../assets/platformp/1.png"),
        },
        {
          title: "快递上门",
          content:
            "业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业在线客服中心。",
          imgStr: require("../assets/platformp/6.png"),
        },
        {
          title: "邻里帮忙",
          content:
            "业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业在线客服中心。",
          imgStr: require("../assets/platformp/2.png"),
        },

        {
          title: "空气治理",
          content:
            "业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业在线客服中心。",
          imgStr: require("../assets/platformp/5.png"),
        },
        {
          title: "衣物洗护",
          content:
            "业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业在线客服中心。",
          imgStr: require("../assets/platformp/3.png"),
        },
        {
          title: "开门换锁",
          content:
            "业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业在线客服中心。",
          imgStr: require("../assets/platformp/4.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    var that = this;
    var browerWidth = window.innerWidth; //浏览器可视宽度
    var baseWidth = 1920; //设计稿宽度
    var zoomValue = browerWidth / baseWidth; //缩放比例计算
    document.getElementById("page").style.transform =
      "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
    window.onresize = function () {
      //窗口尺寸变化时，重新计算和缩放
      browerWidth = window.innerWidth;
      zoomValue = browerWidth / baseWidth;
      document.getElementById("page").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")";
    };
  },
  methods: {
    // setval() {
    //   this.zoomValue = Math.random();
    // },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  // display: inline-block;
  // width: 1920px;
  // transform-origin: 0 0;
  // position: absolute;
  // left: 0;
  text-align: center;
  .mine {
    width: 1600px;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }

  .content {
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    margin-top: 50px;
    p {
      margin: 0;
    }
    .img {
      margin: 0 75px;
    }
    .c-l {
      text-align: right;
    }
    .c-r {
      text-align: left;
    }
    .list {
      margin-top: 20px;
      .p {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 27px;
      }
      .text {
        font-size: 24px;
        font-weight: bold;
        color: #3ca35f;
      }
    }
  }

  .t-con {
    background: url("../assets/platformp/tbj.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    background-color: #ecf2ff;
    margin-top: 100px;
    position: relative;
    .text {
      position: absolute;
      top: 100px;
      left: 25%;
      p:nth-child(1) {
        font-size: 37px;
        font-weight: 400;
        color: #333333;
      }
      p:nth-child(2) {
        text-align: left;
        width: 549px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 27px;
      }
    }
  }
  .bottom {
    margin-top: 100px;
  }
}

//

.page_advantage {
  margin-bottom: 150px;
}
.advantage_bottom {
  margin: 100px 450px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.advantage_list {
  display: inline-block;
  width: 50%;
  margin-bottom: 30px;
  text-align: center;
}
.advantage_content {
  margin-right: 30px;
  // display: flex;
  // width: 90%;
  // height: 100%;

  background-color: #ffffff;
  border-radius: 10rpx;
}
.advantage_left {
  width: 180px;
  height: 160px;
  img {
    width: 100%;
    height: 100%;
  }
}

.right_top {
  padding-top: 20px;
}
.right_topbg {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 86px;
  height: 22px;
  background: #62afaf;
  opacity: 0.2;
  border-radius: 11px;
}
.right_topTxt {
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  text-align: left;
}
.right_bottom {
  margin-top: 22px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: left;
}
</style>
